import React from 'react';
import UserSearch from './components/layout/UserSearch'
import './styles/index.css';
import './styles/custom.css';



function App() {
  return (

    <div className="App">
      <UserSearch/>
    </div>
  );
}

export default App;
